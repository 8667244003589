<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterRegional
                    style="width:100%;"
                    v-model:value="state.params.region"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterProvince
                    style="width:100%;"
                    v-model:region="state.params.region"
                    v-model:value="state.params.provinsi"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterArea
                    style="width:100%;"
                    v-model:region="state.params.region"
                    v-model:provinsi="state.params.provinsi"
                    v-model:value="state.params.area"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterKabupatenKota
                    style="width:100%;"
                    v-model:region="state.params.region"
                    v-model:provinsi="state.params.provinsi"
                    v-model:area="state.params.area"
                    v-model:value="state.params.kabupaten"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterBrand
                    style="width:100%;"
                    v-model:value="state.params.brand"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterATSalesmanWeight
                    style="width:100%;"
                    v-model:value="state.params.weight"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterWeekly
                    placeholder="Pilih Minggu Pertama"
                    v-model:year="state.params.year"
                    v-model:value="state.params.start_week"
                    previous_weekly/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterWeekly
                    placeholder="Pilih Minggu Kedua"
                    v-model:year="state.params.year"
                    v-model:value="state.params.end_week"
                    last_monthly/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <ASelect
                    style="width:100%;"
                    placeholder="Pilih Harga"
                    show-search
                    allow-clear
                    :options="state.priceTypeLists"
                    v-model:value="state.params.calculation_type"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <DownloadExcel
                    :url="state.endpoint"
                    :params="queryParams()"
                    namefile="Summary-RBP"
                    @errors="errorMessage"/>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <TableStandard
                v-if="!state.isFetching"
                :url="state.endpoint"
                :columns="state.columns"
                :params="queryParams()"
                @errors="errorMessage"/>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, nextTick } from 'vue'
import {
    FilterRegional,
    FilterProvince,
    FilterArea,
    FilterKabupatenKota,
    FilterBrand,
} from '@/components/filter'
import FilterATSalesmanWeight from '@/components/filter/FilterATSalesmanWeight'
import FilterWeekly from '@/components/filter/FilterWeekly'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import TableStandard from '@/components/Molecules/TableStandard'
import { FormatNumber } from '@/helpers/utils'
import moment from 'moment'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/globalmixin'

export default defineComponent({
    components: {
        FilterRegional,
        FilterProvince,
        FilterArea,
        FilterKabupatenKota,
        FilterBrand,
        FilterATSalesmanWeight,
        FilterWeekly,
        DownloadExcel,
        TableStandard,
    },
    setup() {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Regional',
                    dataIndex: 'regional',
                },
                {
                    title: 'Provinsi',
                    dataIndex: 'provinsi',
                },
                {
                    title: 'Area',
                    dataIndex: 'area',
                },
                {
                    title: 'Kabupaten/Kota',
                    dataIndex: 'kabupaten',
                },
                {
                    title: 'Produsen',
                    dataIndex: 'produsen',
                },
                {
                    title: 'Merk / Brand',
                    dataIndex: 'brand',
                },
                {
                    title: 'Kemasan / Produk',
                    dataIndex: 'kemasan',
                },
                {
                    title: 'Berat',
                    dataIndex: 'berat',
                },
                {
                    title: 'Harga Jual Minggu Pertama',
                    dataIndex: 'sale_price_first_week',
                    customRender: ({ text }) => FormatNumber(text),
                },
                {
                    title: 'Harga Jual Minggu Kedua',
                    dataIndex: 'sale_price_second_week',
                    customRender: ({ text }) => FormatNumber(text),
                },
                {
                    title: 'Selisih',
                    dataIndex: 'selisih',
                    customRender: ({ text }) => FormatNumber(text),
                },
                {
                    title: 'Keterangan',
                    dataIndex: 'keterangan',
                },
            ],
            endpoint: '/api/report-ats/rsp-movement-v2',
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            params: extractQueryParams({
                q: '',
                region: [],
                provinsi: [],
                area: [],
                kabupaten: [],
                brand: [],
                weight: [],
                year: moment().year(),
                start_week: null,
                end_week: null,
                calculation_type: 'avg',
                page: 1,
                "per-page": 10,
            }),
            priceTypeLists: [
                {
                    label: "Minimal",
                    value: 'min',
                },
                {
                    label: "Maksimal",
                    value: 'max',
                },
                {
                    label: "Rata-Rata",
                    value: 'avg',
                },
                {
                    label: "Modus",
                    value: 'mod',
                },
            ],
        })

        const queryParams = () => {
            let params = state.params

            return updateUrlWithQuery(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            nextTick().then(() => {
                state.isFetching = false
            })
        }

        return {
            state,
            errorMessage,
            queryParams,
            fetchDataList,
        }
    },
})
</script>
