<template>
    <ASelect
        v-model:value="valueState"
        placeholder="Pilih Minggu Ke-"
        allow-clear
        show-search
        not-found-content="Tidak ditemukan"
        option-filter-prop="label"
        option-label-prop="label"
        @search="onSearch"
        @blur="() => (state.params.q = null)"
        @select="() => (state.params.q = null)"
        v-bind="$attrs"
        :loading="state.loading"
        :show-arrow="true"
        style="width:100%">
        <ASelectOption v-for="item in state.data" :key="item.week_number" :label="getLabel(item)">
            <span v-html="highlight(getLabel(item))"></span>
        </ASelectOption>
    </ASelect>
</template>

<script>
import { onMounted, reactive, watch } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'
import { debounce, pickBy, isEmpty } from 'lodash'
import moment from 'moment'

export default {
    props: {
        value: {
            type: [Array, Number, Object, String],
            default: null,
        },
        year: {
            type: [String, Number],
            default: null,
        },
        first_monthly: {
            type: [Boolean],
            default: false,
        },
        last_monthly: {
            type: [Boolean],
            default: false,
        },
        previous_weekly: {
            type: [Boolean],
            default: false,
        },
    },
    emits: ['update:value'],
    setup(props, { emit }) {
        const state = reactive({
            endpoint: '/api/filter/master-week',
            loading: false,
            data: [],
            params: {
                q: '',
                year: props.year,
            },
        })

        const fetchData = () => {
            state.loading = true
            apiClient.get(state.endpoint, { params: pickBy(state.params) })
                .then(({ data }) => {
                    state.data = data

                    if (!props.value) {
                        const { firstWeekId, lastWeekId, previousWeekId } = getFirstAndLastWeekIds(data)
                        if (props.first_monthly) {
                            emit('update:value', firstWeekId)
                        }
                        if (props.previous_weekly) {
                            emit('update:value', previousWeekId)
                        }
                        if (props.last_monthly) {
                            emit('update:value', lastWeekId)
                        }
                    }
                })
                .finally(() => {
                    state.loading = false
                })
        }

        const getLabel = (item) => {
            return `${item.month} W${item.week_month} (${moment(item.start_date).format('DD-MM')} s/d ${moment(item.end_date).format('DD-MM')})`
        }

        const getFirstAndLastWeekIds = (data) => {
            // Dapatkan nama bulan saat ini menggunakan moment
            const currentMonth = moment().format('MMMM')
            const today = moment() // Dapatkan tanggal hari ini

            // Filter data untuk bulan saat ini
            const monthData = data.filter(item => moment(item.start_date).format('MMMM') === currentMonth)

            // Mendapatkan minggu pertama (berdasarkan week_month terkecil)
            const firstWeekId = _.minBy(monthData, 'week_month')?.id || null

            // Mendapatkan minggu terakhir (berdasarkan apakah minggu tersebut mencakup hari ini)
            const lastWeekId = monthData
            .find(item => moment(item.start_date).isBefore(today) && moment(item.end_date).isSameOrAfter(today))?.id || null

            // Mendapatkan minggu sebelumnya (berdasarkan week_month yang lebih kecil dari minggu terakhir)
            const previousWeek = monthData.find(item => item.week_month === (lastWeekId ? (data.find(d => d.id === lastWeekId)?.week_month || 0) - 1 : null))
            const previousWeekId = previousWeek?.id || null

            return { firstWeekId, lastWeekId, previousWeekId }

        }

        onMounted(() => {
            fetchData()
        })

        watch(() => props.year, () => {
            console.log('asasas')
            state.params.year = props.year
        })

        watch(state.params, debounce(() => {
            fetchData()
        }, 300))

        const onSearch = value => {
            state.params.q = value
        }

        const highlight = value => {
            return value.replace(new RegExp(state.params.q, 'gi'), match => {
                return `<span style="background-color: yellow">${match}</span>`
            })
        }

        return {
            state,
            valueState: useVModel(props, 'value'),
            highlight,
            onSearch,
            getLabel,
        }
    },
}
</script>